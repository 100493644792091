import React from "react";
import { CountdownTimer } from "./countDownTimer";

function Time() {
  return (
    <div
      className=" bg-[#e0e6ea] w-full flex justify-center py-10  "
      style={{
        backgroundImage: `
    linear-gradient(to bottom, rgba(255, 255, 255, 0.5), transparent 5%, transparent 95%, rgba(255, 255, 255, 0.5))
  `,
      }}
    >
      <CountdownTimer targetDate={new Date("2024-03-07 09:00").getTime()} />
    </div>
  );
}

export default Time;
